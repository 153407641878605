import api from './api';

class DominioService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/dominios${cadenaBusqueda}`);
  }

}

export default new DominioService();
